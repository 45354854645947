import React from "react"
import PropTypes from "prop-types"
import { Facebook, Instagram, Youtube } from 'react-feather'

const Footer = () => (
  <footer className="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a href="https://www.facebook.com/%D5%84%D5%A5%D6%80-%D5%B4%D5%A1%D5%BD%D5%AB%D5%B6-About-Us-%D0%9E-%D0%BD%D0%B0%D1%81-1686086298317649/?ref=page_internal" className="icon style2" target="_blank">
            <Facebook size={30} />
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/yerevan_yerevantsiner/" className="icon style2 fab fa-instagram" target="_blank">
            <Instagram size={30} />
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/user/ArthurElbakyanTV/" className="icon style2 fab fa-youtube" target="_blank">
            <Youtube size={30} />
            <span className="label">Youtube</span>
          </a>
        </li>
      </ul>
      <p>&copy; Կայքի և հեղինակի բոլոր իրավունքները պաշտպանված են:</p>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
