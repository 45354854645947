import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Header = ({ siteTitle }) => (
  <header>
    <nav id="main-nav">
      <div className="title">
        <Link to='/'>{ siteTitle }</Link>
      </div>
      <ul id="nav-items">
        <li>
          <Link to="/">ԳԼԽԱՎՈՐ</Link>
        </li>
        <li>
          <AnchorLink to='/#about'>ՄԱՍԻՆ</AnchorLink>
        </li>
        <li>
          <AnchorLink to='/#spotlights'>հատվածներ</AnchorLink>
        </li>
        {/* <li>
          <Link to='/videos'>տեսանյութեր</Link>
        </li> */}
        <li>
          <Link to='/blog'>ԲԼՈԳ</Link>
        </li>
        <li>
          <AnchorLink to='/#press'>ՀՈԴՎԱԾՆԵՐ</AnchorLink>
        </li>
        {/* <li>
          <AnchorLink to='/#gallery'>պատշգամբ</AnchorLink>
        </li> */}
        {/* <li>
          <AnchorLink to='/#contact'>ԿԱՊ</AnchorLink>
        </li> */}
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
